.bg--page {
  background: var(--page-bg);
}
.bg--page-alt {
  background: var(--page-bg--alt);
}
.bg--primary {
  background-image: linear-gradient(
    -161deg,
    var(--primary-500) 0%,
    var(--primary-700) 100%
  );
  color: var(--tone-100);
}
