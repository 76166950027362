//shared styles from published library @domgen/dgx-fe-styles-core
@use 'base-styles/base-styles';
//overrides for application from libs/shared/ui-claims-legacy-styles
@use 'claims-legacy-styles/claims-legacy-styles';

//themes
@use 'base-styles/themes/dg-eevee' as theme;
@use 'base-styles/themes/ao';
@use 'base-styles/themes/hoover';

.claims {
  @include theme.dg();
  //overrides from new theme (has white header, claims is currently blue)
  // TODO: remove --header-rebrand override once headers are aligned with sales
  --header: var(--primary-800);
  --header--contrast: var(--tone-100);
  --header-rebrand: var(--tone-800);
  --header--rebrand-color: var(--tone-100);
  --content-width: 562px;

  --footer: var(--tone-700);
  --footer--contrast: var(--tone-100);

  //difference from sales - needs confirming and updating in new theme
  --secondary-interactive-color: var(--secondary-600);

  //button overrides - these are new rules from Chimmy - need to see in place for final approval
  --btn-min-width: 208px;
  --btn-min-width-sm: 208px;
  --btn-max-width: auto;
  --btn-max-width-sm: auto;
  --btn-justify-content: center;
  --btn-text-align: center;
  --btn-padding: 16px;
  --btn-icon-spacing: 16px;

  --btn-border-width: 2px;
  --btn-border-radius: 0;
}

//debug for highlighting old partial bold headings - to be removed after review
.heading__highlight {
  span {
    //color: red!important;
  }
}

h1,
h2 {
  color: var(--tone-700);
}

